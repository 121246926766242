<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()

const isPreview = computed(() => runtimeConfig.public.env !== 'production')
// console.log('isPreview', isPreview.value)
</script>

<template>
  <template v-if="isPreview">
    <slot />
  </template>
</template>
